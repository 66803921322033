.about-container{
    background-color: #1D1D2E;
    width: 100%;
    z-index:100;
    padding: 1% 2%;
    font-size: 0.9em;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    font-size: 1em;

    .simplebar-scrollbar::before{
        background-color: rgba(114, 114, 172,0.5);
        border-radius: 0;
        width: 1em;
        padding: 0;
    }

    .active-line{
        background-color: #273651;
        border: 2px solid #374860;
        width: 110%;
    }

    @keyframes blink {
        0% {opacity: 0}
        49%{opacity: 0}
        50% {opacity: 1}
    }
    
    .blinking-cursor{
        animation: blink 1s infinite;
    }

        
    .code-popup{
        background-color:#1d1d2e;
        border: 1px solid #495374;
        color: #fff;
        width: fit-content;
        padding: 1% 1% 0.5% ;
        margin: 5px 0 5px 70px;
        font-size: 0.5em;
        text-align: center;
        z-index: 1;

        .img-popup{
            width: 146px;
            border-radius: 100%;
            padding-top: 10px;
        }
    }
    .position-container{
        position: relative;

        .background-container{
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;

            .background-text-container{
                display: flex;
                flex-wrap: nowrap;
                padding: 2.5% 0;

                .left{
                    min-width: 285px;
                    width: 10%;
                    overflow: hidden;
                }
            }
        }
    }


    .back-symbols{
        text-overflow: "=";
        white-space: nowrap;
        overflow: hidden;
        margin-right: -1000%;
    }

    .about-activities-container{
        display: flex;
    }

    .about-activities-left{
        width: 65%;
        padding-right: 5%;
    }
    
    .dots{
        color: #67678D;
        font-size: 0.9em;
    }
    
    .code{
        color: #ed736c;
    }
    
    .link{
        color: #4f9fdf;
        text-decoration: underline;

        a{
            color: #4f9fdf;
            
        }
    }
    .type{
        color: #86e1f5;
        font-style: italic;
    }
    .variable{
        color: orange;
        font-style: italic;
    }
    
    .identifier{
        color: #69BE7D;
    }
    .highlight{
        color: white;
        background-color: #527c93;
    }
    
    .comment{
        color: #A676E0;
        font-size: 1em;
        font-weight: bold;
    }
    
    .style{
        color: #E3DE80;
    }
    
    .nested{
        padding: 0 0 0 2%;
    }
    
}

@media (max-width: 768px){
    .about-container{
        // font-size: 0.9em;

        .position-container{
            display: flex !important;
            flex-direction: row;
            justify-content: space-between;

            .code-popup{
                margin: 5px 0;
                display: inline-block;
                margin-right: 5%;

                .portrait-message{ display: none; }
            }
    
            .background-container{
                position: static !important;
                right: 0;
                top: auto !important;
                left: auto !important;
                
                .background-text-container{
                    padding: 0 !important;
                    height: 100%;
                    
                    .background-text{
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;
                        height: 100%;
                    }
                }

                .code-message{
                    padding-bottom: 2%;
                }
            }
        }

        .back-symbols, .left{
                display: none !important;
        }

        .about-activities-container{
            flex-direction: column;

            .about-activities-left{
                width: 100%;
                padding-bottom: 7%;
            }
        }

        .skills{
            padding-right: 5%;
        }
    }
}

@media (max-width: 576px){
    .about-container{
        // font-size: 0.8em;

        .position-container{
            display: flex !important;
            flex-direction: column !important;
    
            .code-popup{
                margin: 5px 0 5px 70px !important;
            }
        }
    }
}