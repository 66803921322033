.explorer{
    background-color: #171727;
    font-family: -apple-system,BlinkMacSystemFont,sans-serif;
    font-weight: 300;
    min-width: 200px;
    max-width: 250px;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-width: 0;
    min-height: 0;

    .header {
        display: flex;
        justify-content: space-between;
        padding: 10px 15px 10px 25px;

        p{ 
            font-size: 1em;
        }
        span{
            font-size: 1.2em;
        }
    }

    .files{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 2;
        min-width: 0;
        min-height: 0;

        .top{
            flex-grow: 1;
            display: grid;
            grid-template-rows: auto auto 2fr;
            min-width: 0;
            min-height: 0;

            .divider{
                display: flex;
                background-color: #1D1D2E;
                padding: 2px 0 1px 0;
                border-bottom: 1px solid #0e0e17;
        
                span{
                    padding-left: 4px;
                    font-size: 1em;
                    line-height: 0;
                }
        
                p{
                    font-size: 0.8em;
                    font-weight: bold;
                    padding: 1px 0 1px 5px;
                }
            }

            .shadow{
                border-bottom: 1px solid #1d1d2e;
                box-shadow: 0px 2px 1px 0px rgba(10, 9, 15, 0.9);
                overflow: hidden;
                z-index:2;
            }

            .links{ 
                z-index:1;
                overflow-y: auto;
                overflow-x:hidden;
                min-width: 0;
                min-height: 0;
                font-size: 1em;
                height: 100%;

                .simplebar-scrollbar::before{
                    background-color: rgba(114, 114, 172,0.5);
                    border-radius: 0;
                    width: 1em;
                    padding: 0;
                }

                .home{
                    margin-top: 1%;
                }

                .folder {
                    display: flex;
                    align-items: center;
                    justify-items: center;
                    padding: 2% 8%;
                    line-height: 0; 
                    
                    p{
                        line-height: normal !important;
                    }

                    .yellow{
                        color: #fbc02d;
                    }

                    .blue{
                        color: #059be5;
                        // color: #03bbd4;
                    }

                    .green{
                        color: #4caf50;
                        // color: #8bc24b;
                    }

                    .purple{
                        color: #a677e0;
                    }

                    .red{
                        color: #ff5252;
                    }

                    span{
                        display: inline-block;
                        line-height: 0;
                        vertical-align: center;
                        text-align: center;
                        font-size: 1em;
                        padding-right: 3%;
                    }
                }
                .folder:hover{
                    background-color: rgba(139, 139, 191, 0.1);
                }

        
                .linkActive{
                    background-color: rgba(53, 53, 97, 0.35);
                }

                .projects{
                    
                    .folder{
                        
                        p{
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }

                    span{
                        padding-left: 10%;
                    }
                }
            }
        }
        .bottom{

            .divider{
                display: flex;
                background-color: #1D1D2E;
                padding: 2px 0 1px 0;
                border-bottom: 1px solid #0e0e17;
        
                span{
                    padding-left: 4px;
                    font-size: 1em;
                    display: inline-block;
                    line-height:0;
                }
        
                p{
                    font-size: 0.8em;
                    font-weight: bold;
                    padding: 1px 0 1px 5px;
                }
            }

            img {
                width: 100%;
            }
        }
    }
}

@media (max-width: 992px){
    .explorer{
        display:none;
    }
}