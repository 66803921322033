.status-bar{
    background-color: #151523;
    border-radius: 0 0 10px 10px;
    font-family: -apple-system,BlinkMacSystemFont,sans-serif;
    font-weight: 300;
    font-size: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;

    .status-left{
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding-left: 10px;

    }
    .block{ 
        display: inline-block;
        padding: 5px 10px;

        .extension{
            display: flex;
            align-items: center;
            gap: 5px;
        }
        span{
            font-size: 0.9em;
            display: inline-block;
            line-height: 0;
        }
    }

    .block:hover{
        background-color: rgb(168, 168, 205,0.1);
    }

    .status-right{
    }
}

@media (max-width: 1200px){
    .status-bar{
        display: none;
    }
}