.iconbar {
    background-color: #2B2B46;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 100;
    height: 100%;
    flex-basis: 70px;

    .icon-button{
        font-size: 2em;
        width: 100%;
        text-align: center;
        color: rgb(132, 132, 179);
        padding: 10% 0%;
    }
    .icon-button:hover{
        color: rgb(231, 231, 231);
    }
    .menu{
        display: none;
    }
}

.icon-bar-navigation{
    background-color: #2b2b46;
    position: relative;

    .hide-menu{
        display: none;
    }

    .show-menu{
        display: block;
        padding: 5%;
        display: flex;
        flex-direction: column;
        gap: 15px;
        align-items: center;
    }

    h1 {
        text-align: center;
        color: #a677e0;
    }

    a {
        color: rgb(132, 132, 179);
        text-align: center;
        font-size: 1.5em;
        width: 100%;
        display: block;
    }
    .linkActive {
        color: rgb(231, 231, 231);
    }
}

@media (max-width: 768px){
    .menu{
        display: initial !important;
    }

    .iconbar {
        flex-direction: row;
        justify-content: space-around;
        height: unset;

        .icon-button{
            width: unset;
            padding: unset;
            padding-top: 1%;
        }
    }
}